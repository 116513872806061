import React, { createContext, Dispatch, useReducer } from "react";
import { Container, Grid } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";
import { Plans } from "./Plans";
import { IDispatch, IPlan, IState } from "./types";
import { HeroSection } from "./HeroSection";
import { customSort } from "./libs";

const reducer = (state: IState, dispatch: IDispatch) => {
  const { sortData } = state;

  return {
    sortData: customSort(sortData, dispatch.type),
    sortType: dispatch.type,
  };
};

export const Context = createContext(
  {} as { state: IState; dispatch: Dispatch<IDispatch> }
);

export const SortableList = () => {
  const data = useStaticQuery<GatsbyTypes.AllServicesQuery>(graphql`
    query AllServices {
      allSanityService {
        edges {
          node {
            id
            name
            slug
            url
            plans {
              _key
              description
              freeTrial {
                url
                times
              }
              price {
                data
                moreThan
              }
              lessonTime
              title
              type
              url
            }
            campaigns {
              _key
              ad {
                html
                id
              }
              url
              until(locale: "ja")
              detail
            }
          }
        }
      }
    }
  `);
  const {
    allSanityService: { edges },
  } = data;

  const allPlans = edges
    .map((elm) =>
      elm.node.plans.map((plan) => {
        const _plan = { ...plan, parent: elm.node } as IPlan;
        return _plan;
      })
    )
    .flat();

  const [state, dispatch] = useReducer(reducer, {
    sortType: "price",
    sortData: customSort(allPlans, "trialTimes"),
  });

  return (
    <Context.Provider value={{ state, dispatch }}>
      <HeroSection />
      <Container maxWidth="md" sx={{ mt: -14, mb: 8 }}>
        <Grid container spacing={4} alignItems="stretch">
          <Plans />
        </Grid>
      </Container>
    </Context.Provider>
  );
};
