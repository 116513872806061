import React, { CSSProperties } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material";
import { IPlan } from "../types";
import { PlanDetail } from "./PlanDetail";
import { navigate } from "gatsby";

export const Plan = (
  props: IPlan & {
    ranking: number;
  }
) => {
  const { _key, title, ranking, parent, freeTrial, url } = props;
  const no1 = ranking === 1;
  let rankingOrder = ranking;
  let rankingBackgroundColor: CSSProperties["color"] = "#fff";
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  switch (ranking) {
    case 1:
      rankingBackgroundColor = "#D6AF36";
      if (matches) {
        rankingOrder = 2;
      }
      break;
    case 2:
      rankingBackgroundColor = "#A7A7AD";
      if (matches) {
        rankingOrder = 1;
      }
      break;
    case 3:
      rankingBackgroundColor = "#A77044";
  }

  return (
    <Grid
      item
      key={_key}
      xs={12}
      sm={no1 ? 12 : 6}
      md={4}
      order={rankingOrder}
      display="flex"
      flexDirection="column"
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          my:
            (matches && ranking === 2 && 4) || (matches && ranking === 3 && 4),
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{
                backgroundColor: rankingBackgroundColor,
                color: (theme) =>
                  theme.palette.getContrastText(rankingBackgroundColor),
              }}
            >
              {ranking}
            </Avatar>
          }
          title={title}
          subheader={
            <Link
              href={`/${parent.slug}`}
              color="inherit"
              underline="none"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/${parent.slug}`);
              }}
            >
              {parent.name}
            </Link>
          }
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
          }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <PlanDetail {...props} />
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant={no1 ? "contained" : "outlined"}
            href={parent.slug}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${parent.slug}`);
            }}
          >
            詳細を見る
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
