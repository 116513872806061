import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../containers/Layout";
import { SortableList } from "../components/SortableList";
import { Box } from "@mui/material";
import { Seo } from "../components/Seo";

const IndexPage = ({
  data: {
    sanitySiteSettings: { title, description },
  },
}: PageProps<{ sanitySiteSettings: GatsbyTypes.SanitySiteSettings }>) => {
  return (
    <Layout>
      <Seo title={title} description={description} />
      <Box component="main">
        <SortableList />
      </Box>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    sanitySiteSettings {
      title
      description
    }
  }
`;
