const sortOptionValues = ["price", "trialTimes"] as const;
export type ISortType = typeof sortOptionValues[number];

export const sortOptions: Array<{
  title: string;
  value: ISortType;
}> = [
  { title: "無料体験が多い順", value: "trialTimes" },
  { title: "価格が安い順", value: "price" },
];

export interface IState {
  sortType: ISortType;
  sortData: IPlan[];
}

export interface IDispatch {
  type: ISortType;
}

export type IPlan = GatsbyTypes.SanityPlan & {
  parent: GatsbyTypes.SanityService;
};
