import React from "react";
import { useContext } from "react";
import { Context } from "..";
import { Plan } from "./Plan";

export const Plans = () => {
  const {
    state: { sortData },
  } = useContext(Context);

  return (
    <>
      {sortData.map((plan, index) => (
        <Plan key={`SortableList__${index}`} ranking={++index} {...plan} />
      ))}
    </>
  );
};
