import React from "react";
import { Stack, Typography } from "@mui/material";
import { IPlan } from "../types";
import { Campaign, Check } from "@mui/icons-material";
import { isValidCampaign } from "../../../lib/helpers";

export const PlanDetail = (props: IPlan) => {
  const {
    price,
    freeTrial,
    type,
    description,
    parent: { campaigns },
  } = props;

  return (
    <Stack>
      <Stack alignItems="center">
        <Typography
          component="p"
          variant="subtitle2"
          color={(theme) => theme.palette.text.secondary}
        >
          料金
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="baseline"
          mb={2}
        >
          <Typography component="p" variant="h4" color="text.primary">
            ¥{price.data.toLocaleString()}
            {price.moreThan && "~"}
          </Typography>
          <Typography component="p" variant="h6" color="text.secondary">
            /{type === "daily" ? "日" : "月"}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        margin="auto"
        sx={{ mb: 4 }}
      >
        <Typography
          variant="subtitle2"
          color={(theme) => theme.palette.text.secondary}
        >
          無料体験
        </Typography>
        <Typography component="div" variant="h4" color="text.primary">
          {freeTrial ? `${freeTrial.times}回` : "-"}
        </Typography>
      </Stack>
      <Stack>
        {campaigns.map(({ _key, detail, until, ad }, index) => {
          const text = ad
            ? ad.html.replace(/<[^>]+>/g, "")
            : isValidCampaign(until)
            ? detail
            : false;

          return (
            text && (
              <Stack key={_key} flexDirection="row" mt={index !== 0 && 0.5}>
                <Campaign fontSize="small" color="primary" sx={{ mr: 0.5 }} />
                <Typography
                  variant="subtitle2"
                  color={(theme) => theme.palette.text.primary}
                >
                  {text}
                </Typography>
              </Stack>
            )
          );
        })}
        {description.map((text, index) => (
          <Stack
            key={index}
            flexDirection="row"
            color={(theme) => theme.palette.text.secondary}
            mt={0.5}
          >
            <Check fontSize="small" color="inherit" sx={{ mr: 0.5 }} />
            <Typography variant="subtitle2">{text}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
