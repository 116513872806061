import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { Context } from ".";
import { IDispatch, sortOptions } from "./types";

export const HeroSection = () => {
  const { dispatch } = useContext(Context);
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;

  return (
    <Box
      sx={{
        pt: 8,
        pb: 16,
        backgroundColor,
      }}
    >
      <Container maxWidth="md">
        <Stack alignItems="center">
          <Select
            defaultValue={sortOptions[0].value}
            variant="standard"
            sx={{
              fontSize: 24,
              color: (theme) => theme.palette.getContrastText(backgroundColor),
            }}
            onChange={(e) => {
              dispatch({
                type: e.target.value as IDispatch["type"],
              });
            }}
          >
            {sortOptions.map(({ title, value }, index) => (
              <MenuItem key={index} value={value}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Container>
    </Box>
  );
};
