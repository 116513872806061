import { IPlan, ISortType } from "./types";

export const customSort = (sortData: IPlan[], sortType: ISortType) => {
  switch (sortType) {
    case "price":
      sortData.sort((a, b) => a.price.data - b.price.data);
      break;
    case "trialTimes":
      sortData.sort((a, b) => {
        const first = a.freeTrial ? a.freeTrial.times : 0;
        const second = b.freeTrial ? b.freeTrial.times : 0;
        return second - first;
      });
    default:
      break;
  }
  return sortData;
};
